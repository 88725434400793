.wheel-container {
  position: relative;
  width: 550px;
  height: 550px;
  margin: 20px;
}

.wheel {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid #C00; /* Red border */
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.5);
  transform: rotate(0deg);
  transition: transform 6s cubic-bezier(0.33, 1, 0.36, 1);
  background: radial-gradient(circle at center, #ffcc00, #cc0000); /* Gradient background */
}

.wheel-segment {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0% 0%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);

}

.wheel-segment-content {
  width: 240px;
  padding-left: 60px;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wheel-segment-name {
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  text-align: right;
  transform: rotate(18deg);
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis; 
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.arrow {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 40px solid rgb(193, 0, 0);
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg); /* Rotate 180 degrees */
}

.center-circle {
  width: 100px;
  height: 100px;
  background-color: #ffd700;
  background: radial-gradient(circle at center, #ffcc00, #ffd500); /* Gradient background */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.5);
}

.wheel-segment:nth-child(1) { background-color: #FF0000;  }
.wheel-segment:nth-child(2) { background-color: #008000;  }
.wheel-segment:nth-child(3) { background-color: #FF8C00;  }
.wheel-segment:nth-child(4) { background-color: #0000FF;  }
.wheel-segment:nth-child(5) { background-color: #FFFF00;  }
.wheel-segment:nth-child(6) { background-color: #800080;  }
.wheel-segment:nth-child(7) { background-color: #FFA500;  }
.wheel-segment:nth-child(8) { background-color: #00CED1;  }
.wheel-segment:nth-child(9) { background-color: #DC143C;  }
.wheel-segment:nth-child(10) { background-color: #00FF00;  }
.wheel-segment:nth-child(11) { background-color: #FF4500;  }
.wheel-segment:nth-child(12) { background-color: #4682B4;  }

@media (max-width: 800px) {
  .wheel-container {
    width: 350px;
    height: 350px;
  }
  .wheel-segment {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 0% 0%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
  
  }
  
  .wheel-segment-content {
    width: 150px;
    padding-left: 40px;
    height: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .wheel-segment-name {
    color: #ffffff;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    text-align: right;
    transform: rotate(18deg);
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis; /* Добавлено свойство для обрезки текста и добавления троеточия */
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }

}

@media (max-width: 450px) {
  .wheel-container {
    width: 250px;
    height: 250px;
  }
  .wheel-segment {
    width: 60%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 0% 0%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
  
  }
  
  .wheel-segment-content {
    width: 100px;
    padding-left: 25px;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .wheel-segment-name {
    color: #ffffff;
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    text-align: right;
    transform: rotate(20deg);
    overflow: hidden;
    text-overflow: ellipsis; /* Добавлено свойство для обрезки текста и добавления троеточия */
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  .center-circle {
    width: 50px;
    height: 50px;
    background-color: #ffd700;
    background: radial-gradient(circle at center, #ffcc00, #ffd500); /* Gradient background */
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.5);
  }

}
