@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: 'Montserrat', sans-serif;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }